.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 20px;
            background-color: #f4f4f4;
        }

        

        .container {
            max-width: 900px;
            margin: 0 auto;
            background-color: white;
            padding: 40px;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        }

        a {
            color: black;
            font-size: 1rem;
            text-decoration: none;
            position: relative;
        }

        a:hover .tooltip {
            visibility: visible;
            opacity: 1;
        }

        .tooltip {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.3s;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            background-color: #fffae6;
            color: black;
            border: 1px solid black;
            border-radius: 5px;
            padding: 5px 10px;
            white-space: nowrap;
            z-index: 10;
            margin-top: 5px;
        }

        h1, h2, H4 {
            text-align: center;
            margin-bottom: 10px;
            text-transform: uppercase;
        }

        h1 {
            font-size: 2.5rem;
            margin-top: 0;
        }

        h2 {
            font-size: 1.5rem;
            margin-top: 40px;
        }

        h4 {
            font-size: 1.7rem;
            margin-top: 40px;
        }

        p {
            text-align: center;
            line-height: 1.6;
            margin-bottom: 20px;
        }

        .section {
            margin-bottom: 40px;
        }

        .data-table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
        }

        .data-table td {
            padding: 10px;
            vertical-align: top;
            border-bottom: 1px solid #ddd;
        }

        .data-table td:first-child {
            font-weight: bold;
        }

        .data-table td:first-child span {
            font-weight: normal;
        }

        .data-table td:last-child {
            text-align: right;
        }

        @media (max-width: 600px) {
            .container {
                padding: 20px;
            }
        }

        footer {
            text-align: center;
            font-size: 0.9rem;
            margin-top: 40px;
            color: #999;
        }

